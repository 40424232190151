import { type FC } from 'react'
import { BrowserRouter as Router, Switch } from 'react-router-dom'

import { ProvideAuth } from '@lib/hooks'
import { ErrorBoundary, ScrollToTop } from '@lib/components'

import { AuthenticatedRoute } from './pages/Login'
import { Search } from './pages/Search'
import { Schedule } from './pages/Schedule'
import { browserExceptions } from '@lib/services'
import { QueryClient, QueryClientProvider } from 'react-query'
import { FGSearch } from './pages/FGSearch'
import { FGSchedule } from './pages/FGSchedule'

void browserExceptions.initialize()
const queryClient = new QueryClient()

export const App: FC = () => {
  return (
    <ErrorBoundary component={<h1>An error occurred - sorry!</h1>}>
      <ProvideAuth>
        <QueryClientProvider client={queryClient}>
          <Router>
            <ScrollToTop />
            <Switch>
              <AuthenticatedRoute exact path="/">
                <Search />
              </AuthenticatedRoute>
              <AuthenticatedRoute path="/donor/:donorId/schedule/:scheduleId">
                <Schedule />
              </AuthenticatedRoute>
              <AuthenticatedRoute exact path="/friday-giving">
                <FGSearch />
              </AuthenticatedRoute>
              <AuthenticatedRoute path="/donor/:donorId/friday-giving-schedule/:scheduleId">
                <FGSchedule />
              </AuthenticatedRoute>
            </Switch>
          </Router>
        </QueryClientProvider>
      </ProvideAuth>
    </ErrorBoundary>
  )
}
