import { useEffect, useState } from 'react'
import { Alert, Container } from 'react-bootstrap'
import SearchForm from '../components/SearchForm'
import { useHistory, useLocation } from 'react-router-dom'
import { SearchResults } from '../components/SearchResults'
import { ScheduleAPI } from '../service/schedule'
import { Header } from '~/components/Header'

const useQueryParams = () => new URLSearchParams(useLocation().search)

const CAMPAIGNS = []
for (let index = new Date().getFullYear(); index >= 2019; index--) {
  CAMPAIGNS.push(`My Ten Nights ${index}`, `My Ten Days ${index}`);
}
const SearchPageContent = ({ query, campaign }) => {
  const [error, setError] = useState('')
  const [schedules, setSchedules] = useState(null)
  const [truncated, setTruncated] = useState(false)
  const history = useHistory()

  useEffect(() => {
    const fetchData = async () => {
      if (!query) {
        return
      }
      try {
        setError('')
        const s = await ScheduleAPI.search(query, campaign)
        setSchedules(s.data)
        setTruncated(s.truncated)
      } catch (e) {
        setError(`Error searching: ${e.message}`)
      }
    }

    fetchData()
  }, [query, campaign])

  const onSubmit = (q, c) => {
    history.push(`/?query=${q}${c ? `&campaign=${c}` : ''}`)
  }

  return <>
    <Container>
      <h1>Search </h1>
      <p>Search for name, email, last 4 card digits or postcode</p>
      <SearchForm
        query={query}
        campaign={campaign}
        onSubmit={onSubmit}
        campaigns={CAMPAIGNS}
      />
      {error && <Alert className="my-3" variant="danger">{error}</Alert>}
      <SearchResults schedules={schedules} truncated={truncated} />
    </Container>
  </>
}

export const Search = () => {
  const queryParams = useQueryParams()
  const query = queryParams.get('query')
  const campaign = queryParams.get('campaign') ?? ''
  return <>
    <Header item="MTNS" />
    <SearchPageContent query={query} campaign={campaign} />
  </>
}
