import { type FC } from 'react'
import { Button, Form, Modal } from 'react-bootstrap'
import { useForm } from 'react-hook-form'
import { updateDonorEmail } from '~/service/donor'
import { useMutation } from 'react-query'

export const UpdateEmailModal: FC<{ show: boolean, donorId: string, fromEmail: string, handleClose: () => void }> = ({ show, donorId, fromEmail, handleClose }) => {
  interface UpdateEmailFormData {
    email: string
    ticket: string
  }

  const { register, handleSubmit, formState: { errors } } = useForm<UpdateEmailFormData>({
    defaultValues: {
      email: '',
      ticket: ''
    }
  })

  const { mutate: submit, isLoading, isError } = useMutation(async (data: UpdateEmailFormData): Promise<void> => {
    await updateDonorEmail({
      donor_id: donorId,
      from: fromEmail,
      to: data.email,
      unique_reference: data.ticket,
      type: 'emailUpdate'
    })
  }, {
    onSuccess: () => {
      handleClose()
    }
  })

  return <Modal show={show} onHide={handleClose}>
    <Modal.Header closeButton>
      <Modal.Title>Update Email</Modal.Title>
    </Modal.Header>
    {/* eslint-disable-next-line @typescript-eslint/no-misused-promises */}
    <Form onSubmit={handleSubmit(data => submit(data))}>
      <Modal.Body>
        <Form.Label>New Email</Form.Label>
        <Form.Control
          type="email"
          placeholder="Enter email"
          id="email"
          {...register('email', { required: 'Email to update to is missing' })}
        />
        <p className="mt-2 text-xs text-right text-sz-pink">{errors.email?.message ?? ''}</p>
        <Form.Label>Ticket Number</Form.Label>
        <Form.Control
          type="text"
          placeholder="Enter ticket number"
          id="ticket"
          {...register('ticket', { required: 'Ticket number is missing' })}
        />
        <p className="mt-2 text-xs text-right text-sz-pink">{errors.ticket?.message ?? ''}</p>
      </Modal.Body>
      {isError && <p className="text-sm text-right text-sz-pink">Oops! Something went wrong saving this info</p>}
      <Modal.Footer>
        <Button variant="secondary" onClick={handleClose}>
          Close
        </Button>
        <Button disabled={isLoading} variant="primary" type="submit">
          Update Email
        </Button>
      </Modal.Footer>
    </Form>
  </Modal>
}
