import { withAuth } from '@lib/hooks'

const read = withAuth(async (donorId) => {
  const res = await fetch(`/api/marketing/${donorId}`)

  if (res.status !== 200) {
    throw new Error('Unexpected status ' + res.status)
  }

  return res.json()
})

const setPreference = withAuth(async (donorId, template) => {
  const { email, transport, identifier, source, channel, value, sms } = template

  if (identifier === '' || channel === '') {
    throw new Error('Invalid template')
  }

  const payload = transport === 'email'
    ? { email: identifier, value }
    : { phone: identifier, value, sms: sms ?? false, email }

  const res = await fetch(`/api/marketing/${donorId}/${source}/${channel}`, {
    method: 'POST',
    body: JSON.stringify(payload)
  })

  if (res.status !== 200) {
    throw new Error('Unexpected status ' + res.status)
  }

  return res.json()
})

export default {
  read,
  setPreference
}
