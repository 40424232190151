import { type FC } from 'react'
import { Button, Form, Modal } from 'react-bootstrap'
import { useForm } from 'react-hook-form'
import { deleteDonorEmail } from '~/service/donor'
import { useMutation } from 'react-query'

export const DeleteEmailModal: FC<{ show: boolean, donorId: string, email: string, handleClose: () => void }> = ({ show, donorId, email, handleClose }) => {
  interface DeleteEmailFormData {
    ticket: string
  }

  const { register, handleSubmit, formState: { errors } } = useForm<DeleteEmailFormData>({
    defaultValues: {
      ticket: ''
    }
  })

  const { mutate: submit, isLoading, isError } = useMutation(async (data: DeleteEmailFormData): Promise<void> => {
    await deleteDonorEmail({
      donor_id: donorId,
      email,
      unique_reference: data.ticket,
      type: 'rte'
    })
  }, {
    onSuccess: () => {
      handleClose()
    }
  })

  return <Modal show={show} onHide={handleClose}>
    <Modal.Header closeButton>
      <Modal.Title>Delete Email</Modal.Title>
    </Modal.Header>
    {/* eslint-disable-next-line @typescript-eslint/no-misused-promises */}
    <Form onSubmit={handleSubmit(data => submit(data))}>
      <Modal.Body>

        <Form.Group controlId="formEmail">
          <Form.Label>Ticket Number</Form.Label>
          <Form.Control
            type="text"
            placeholder="Enter ticket number"
            id="ticket"
            {...register('ticket', { required: 'Ticket number is missing' })}
          />
          <p className="mt-2 text-xs text-right text-sz-pink">{errors.ticket?.message ?? ''}</p>
        </Form.Group>
        <div className="mt-2 mb-3 text-xs text-danger">The donor's email address and all associated data will be deleted as a result of this action. Since there won't be a record of the donor's personal information or past contributions, refunds, tax receipts, etc. won't be possible. Before moving on, please make sure you have the donor's confirmation. Would you like to Delete the information for this donor?</div>
      </Modal.Body>
      {isError && <p className="text-sm text-right text-sz-pink">Oops! Something went wrong saving this info</p>}
      <Modal.Footer>
        <Button variant="secondary" onClick={handleClose}>
          Close
        </Button>
        <Button disabled={isLoading} variant="outline-danger" type="submit">
          Delete Email
        </Button>
      </Modal.Footer>
    </Form>
  </Modal>
}
