import * as Sentry from '@sentry/react'
import { useHistory } from 'react-router-dom'
import { Breadcrumb, Nav, Navbar } from 'react-bootstrap'
import { Link } from 'react-router-dom'

import { useAuth } from '@lib/hooks'

const PageHeader = ({ email, onLogout, scheduleId, donationID, item }) => {
  const logout = () => {
    // Record breadcrumb
    Sentry.addBreadcrumb({
      category: 'auth.logout',
      message: 'Logged out user ' + email,
      level: 'info'
    })

    // Logout logic
    onLogout()
    onHome()

    // Event enriching
    Sentry.setUser(null)
  }

  const urlOptions = [
    {
      url: '/',
      title: 'Mytennights Schedules',
      id: 'MTNS'
    },
    {
      url: '/friday-giving',
      title: 'Friday Giving Schedules',
      id: 'FGS'
    }
  ]
  const currentProduct = urlOptions.filter(link => link.id === item)[0]
  const onHome = () => history.push(currentProduct.url)
  return <div className='px-4'>
    <Navbar bg="light" expand="lg" >
      <Navbar.Brand>Customer Support</Navbar.Brand>
      <Navbar.Toggle aria-controls="basic-navbar-nav" />
      <Navbar.Collapse id="basic-navbar-nav">
        <Nav className="ml-auto">
          <Nav.Link onClick={logout}>
            {email}{' '}
            <i
              className="small"
            >
              (log out)
            </i>
          </Nav.Link>
        </Nav>
      </Navbar.Collapse>
    </Navbar>
    <div className='flex gap-x-2 py-2'>
      {urlOptions.map(link => <Link to={link.url} className={` ${link.id === item ? 'text-mtn-blue-500' : '' } hover:bg-mtn-blue-500 p-2`}>{link.title}</Link>)}
    </div>
    <Breadcrumb>
      <Breadcrumb.Item
        onClick={onHome}
        style={{ cursor: 'pointer' }}
        active
      >
        {currentProduct.title}
      </Breadcrumb.Item>
      {scheduleId && <Breadcrumb.Item active>{scheduleId}</Breadcrumb.Item>}
    </Breadcrumb>
  </div>
}

export const Header = ({ scheduleId, item = 'MTNS' }) => {
  const history = useHistory()
  const { user, logout } = useAuth()

  // Identify impacted user
  if (user !== null) {
    // Record breadcrumb
    Sentry.addBreadcrumb({
      category: 'auth.login',
      message: 'Authenticated user ' + user.email,
      level: 'info'
    })

    // Event enriching
    // https://docs.sentry.io/platforms/javascript/guides/react/enriching-events/identify-user/
    Sentry.setUser({ email: user.email })
  }

  return <PageHeader email={user?.email} item={item} onLogout={logout} scheduleId={scheduleId} />
}
