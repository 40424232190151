import { useEffect, useState } from 'react'
import { useParams } from 'react-router-dom'
import { Alert, Card, Col, Container, Row } from 'react-bootstrap'
import { ScheduleAPI } from '~/service/schedule'
import marketingPreferences from '../service/marketing'
import FGScheduleSummary from '../components/FGScheduleSummary'
import { PaymentBreakdown } from '~/components/PaymentBreakdown'
import MarketingPreferences from '../components/MarketingPreferences'
import { Header } from '~/components/Header'
import AdjustedPaymentBreakdown from "../components/AdjustedPayment";
import { ScheduleOptionsCard } from "~/components/ScheduleOptionsCard";
import { RunBreakdown } from '~/components/RunBreakdown'
import { FGScheduleOptionsCard } from '~/components/FGScheduleOptionsCard'


const ScheduleDetails = ({ donorId, scheduleId }) => {
  const [error, setError] = useState('')
  const [schedule, setSchedule] = useState(null)
  const [runs, setRuns] = useState(null)
  const [loading, setLoading] = useState(false)

  useEffect(() => {
    const fetchData = async () => {
      try {
        setLoading(true)
        const s = await ScheduleAPI.readFridayGiving(donorId, scheduleId)
        setSchedule(s.schedule)
        setRuns(s.runs)
      } catch (e) {
        setError(`Error loading friday giving schedule: ${e.message}`)
      } finally {
        setLoading(false)
      }
    }

    fetchData()
  }, [donorId, scheduleId])

  if (error && !schedule) {
    return <Container><Alert variant="danger" className="my-3">{error}</Alert></Container>
  }

  if (loading || !schedule) {
    return <Container><h2>Loading...</h2></Container>
  }

  const refund = async (index) => {
    try {
      setError('')
      const s = await ScheduleAPI.refundFridayGiving(donorId, scheduleId, index)
      alert('Refund has been requested. Please note it can take 5-10 days to appear')
      setSchedule(s.schedule)
      setRuns(s.runs)
    } catch (e) {
      setError(`Error requesting refund: ${e.message}`)
    }
  }

  const toggleCancellation = async () => {
    try {
      setError('')
      const s = await ScheduleAPI.setCancelledFridayGiving(donorId, scheduleId, !schedule.cancelled)
      setSchedule(s.schedule)
      setRuns(s.runs)
    } catch (e) {
      setError(`Error changing schedule cancelled status: ${e.message}`)
    }
  }

  const resendReceipt = async (index) => {
    if (!window.confirm(`Are you sure you wish to resend the email receipt for  payment date ${index}?`)) {
      return
    }
    try {
      setError('')
      await ScheduleAPI.resendFridayGivingReceipt(donorId, scheduleId, index)
      alert('Run receipt email has been resent for payment date ' + index)
    } catch (e) {
      setError(`Error resending receipt: ${e.mesage}`)
    }
  }

  return <>
    <Container>
      {error && <Alert className="my-3" variant="danger">{error}</Alert>}
      <Row>
        <Col xs={12} md={8}>
          <FGScheduleSummary schedule={schedule} donorId={donorId} />
          <RunBreakdown
            onRefund={refund}
            onResendReceipt={resendReceipt}
            schedule={schedule} runs={runs} />
        </Col>
        
        <Col xs={12} md={4}>
          <FGScheduleOptionsCard
            donorId={donorId}
            schedule={schedule}
            scheduleId={scheduleId}
            toggleCancellation={toggleCancellation}
            />
        </Col>
      </Row>
    </Container>
  </>
}

export const FGSchedule = () => {
  const { scheduleId, donorId } = useParams()
  return <>
    <Header scheduleId={scheduleId} />
    <ScheduleDetails donorId={donorId} scheduleId={scheduleId} />
  </>
}
