import { Link } from 'react-router-dom'
import time from '../service/time'
import { displayCurrency, GetCurrencyByString } from '@lib/services'
import { Alert, Button, Card, Col, ListGroup, ListGroupItem, Row } from 'react-bootstrap'
import { type ScheduleListResponse } from '~/data/scheduleListResponse'
import { type FC } from 'react'
import moment from 'moment'
import { type FridayGivingDTO } from '@shamaazi/mytennights'

const campaignDisplayNames: Record<string, string> = {
  mtn2020: 'My Ten Nights 2020',
  mtn2019: 'My Ten Nights 2019',
  mtd2020: 'My Ten Days 2020',
  mtn2021: 'My Ten Nights 2021'
}

interface ScheduleCardProps {
  schedule: ScheduleListResponse
}

export const getFridayGivingAmount = (fridayGiving: FridayGivingDTO): string => {
  const amount = fridayGiving.pay_fees ? (fridayGiving.amount + fridayGiving.fees + fridayGiving.tip_amount + fridayGiving.tip_fees) : (fridayGiving.amount + fridayGiving.tip_amount)
  return displayCurrency({ amount, currency: GetCurrencyByString(fridayGiving.currency) })
}

const ScheduleCard: FC<ScheduleCardProps> = ({ schedule }) => {
  const isFGS = !!schedule.record_type
  return <Col key={schedule.schedule_id ?? schedule.id} xs={12} md={6} lg={6} xl={4}>
    {!isFGS
      ? <Card className="my-3 rounded shadow-lg">
      <Card.Body className="pb-0">
        <Card.Title>Schedule for {schedule.donor_name}</Card.Title>
        <Card.Subtitle className="mb-4 text-muted">
          Created at {time.formatTime(schedule.created_time)} on {time.formatDate(schedule.created_time)}
        </Card.Subtitle>
      </Card.Body>
      <ListGroup className="list-group-flush">
        <ListGroupItem>
          <strong>Email:</strong> {schedule.email}
          <br />
          <strong>Postcode:</strong> {schedule.postcode}
          <br />
          <strong>Card ending:</strong> {schedule.last_4_card_digits}
          <br />
          <strong>Campaign:</strong> {campaignDisplayNames[schedule.campaign]}

        </ListGroupItem>
        <ListGroupItem>
          <strong>Charity:</strong> {schedule.charity}
          <br />
          <strong>Total:</strong> {displayCurrency({ amount: schedule.total, currency: schedule.currency })}
        </ListGroupItem>
        {
          schedule.cancelled
            ? <ListGroupItem><Card.Text className="text-danger">Cancelled</Card.Text> </ListGroupItem>
            : <></>
        }
        <ListGroupItem>
          <Card.Text>
            {schedule.completed_payments} of {schedule.number_of_payments} donations completed
          </Card.Text>
          {
            schedule.failed_payments
              ? <Card.Text className="text-danger">{schedule.failed_payments} donation{schedule.failed_payments > 1 ? 's' : ''} failed</Card.Text>
              : <></>
          }
        </ListGroupItem>
        <ListGroupItem>
          <Card.Text>
            Givematch Code: {schedule.givematch_share_code}
          </Card.Text>
        </ListGroupItem>
      </ListGroup>
      <Card.Body className="text-center __my-0">
        <Link to={`/donor/${schedule.donor_id}/schedule/${schedule.schedule_id}`}>
          <Button variant="outline-primary">View</Button>
        </Link>
      </Card.Body>
    </Card>
      : <Card className="my-3 rounded shadow-lg">
      <Card.Body className="pb-0">
        <Card.Title>Schedule for {schedule.donor_details.first_name + ' ' + schedule.donor_details.last_name}</Card.Title>
        <Card.Subtitle className="mb-4 text-muted">
          Created at {time.formatTime(schedule.created_time)} on {time.formatDate(schedule.created_time)}
        </Card.Subtitle>
      </Card.Body>
      <ListGroup className="list-group-flush">
        <ListGroupItem>
          <strong>Email:</strong> {schedule.email}
          <br />
          <strong>Postcode:</strong> {schedule.donor_details.post_code }
          <br />
          <strong>Card ending:</strong> {schedule.donor_details.last_4_card_digits }

        </ListGroupItem>
        <ListGroupItem>
          <strong>Charity:</strong> {schedule.charity_name}
          <br />
          <strong>Total:</strong> { getFridayGivingAmount(schedule)}
        </ListGroupItem>
        {
          schedule.cancelled
            ? <ListGroupItem><Card.Text className="text-danger">Cancelled</Card.Text> </ListGroupItem>
            : <></>
        }
      </ListGroup>
      <Card.Body className="text-center __my-0">
        <Link to={`/donor/${schedule.donor_id}/friday-giving-schedule/${schedule.id}`}>
          <Button variant="outline-primary">View</Button>
        </Link>
      </Card.Body>
    </Card>
    }

  </Col>
}

interface SearchResultsProps {
  schedules: ScheduleListResponse[]
  truncated: boolean
}

export const SearchResults: FC<SearchResultsProps> = ({ schedules, truncated }) => {
  if (!schedules) {
    return <></>
  }
  if (schedules && schedules.length === 0) {
    return <Alert className="my-3" variant="warning">No schedules found</Alert>
  }
  schedules = schedules.sort((a, b) => moment(b.created_time).valueOf() - moment(a.created_time).valueOf())

  const newSchedules = schedules.filter(s => s.is_current)
  const oldSchedules = schedules.filter(s => !s.is_current)

  return <>
    {truncated && <Row className="my-3 mx-0"><p>Search results were truncated...</p></Row>}
    <Row className="my-3">
      {newSchedules.map((s, i) => <ScheduleCard key={i} schedule={s} />)}
      {oldSchedules.length > 0 ? <Col xs={12}><h2>Previous Schedules</h2></Col> : <></>}
      {oldSchedules.map((s, i) => <ScheduleCard key={i} schedule={s} />)}
    </Row>
  </>
}
