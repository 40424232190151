import { type FC } from 'react'
import { Payment, sortPayments } from './PaymentBreakdown'
import { Row } from 'react-bootstrap'
import { type RefundFn, type ResendReceiptFn } from '~/service/types'
import { type APISchedule } from '@shamaazi/mytennights'

const AdjustedPaymentBreakdown: FC<{ schedule: APISchedule, onRefund: RefundFn, onResendReceipt: ResendReceiptFn }> = ({ schedule, onRefund, onResendReceipt }) => {
  const payments = sortPayments(schedule.adjusted_payments)
  return <>
    <h3 className="mb-1 mt-4">Adjusted Payments</h3>
    <hr />
    <p>These payments will all be taken together as one payment on the 27th night</p>
    <Row>
      {payments.map(payment => <Payment
        campaign={schedule.campaign}
        curr={schedule.currency}
        key={payment.index}
        payFees={schedule.pay_fees}
        onRefund={onRefund}
        onResendReceipt={onResendReceipt}
        actionable={schedule.actionable}
        payment={payment}
        hideStatus
        schedule={schedule}
        />
      )}

    </Row>
  </>
}

export default AdjustedPaymentBreakdown
