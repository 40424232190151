import { Fragment, useState } from 'react'
import { Button, Card, Col, Row } from 'react-bootstrap'
import time from '../service/time'
import { displayCurrency } from '@lib/services'
import { useMutation } from 'react-query'
import { getStripe } from '@shamaazi/mytennights'
import { isCampaignInCurrentYear } from '@lib/services'
export const Payment = ({ curr, payment, onRefund, payFees, onResendReceipt, actionable, campaign, hideStatus, schedule }) => {
  const [refunding, setRefunding] = useState(false)
  const [paymentState, setPaymentState] = useState(payment)
  const refund = async () => {
    const p = prompt(`Please type 'refund' to refund the payment for index ${paymentState.index}`)
    if (p !== 'refund') {
      return
    }

    setRefunding(true)
    await onRefund(paymentState.index)
  }

  const { isLoading: retryLoading, isError: isRetryError, mutate: retryPayment } = useMutation(async () => {
    const stripe = await getStripe(schedule.charity_id)
    if (stripe === null) {
      throw new Error('Stripe object was not properly initialised')
    }
    const result = await stripe?.confirmCardPayment(payment?.payment_intent_client_secret ?? '', {
      payment_method: schedule?.payment_method
    })

    if (result?.error != null) {
      throw new Error(result?.error?.message)
    }

    return result?.paymentIntent?.status ?? ''
  }, {
    onSuccess: (data) => {
      setPaymentState({ ...paymentState, status: data })
    }
  })

  const resendReceipt = () => onResendReceipt(paymentState.index)

  const causes = paymentState.breakdown.causes.map(
    c => <Fragment key={c.name}><br />{c.name} - {displayCurrency({ amount: c.amount, currency: curr })}</Fragment>
  )

  const mtn = paymentState.breakdown.tip
    ? <><br />Tip - {displayCurrency({ amount: paymentState.breakdown.tip, currency: curr })}</>
    : <></>

  const fees = payFees
    ? <><br />Fee - {displayCurrency({ amount: paymentState.breakdown.fee, currency: curr })}</>
    : <></>
  return <Col key={paymentState.index} xs={12} md={6} xl={4}>
    <Card className="my-3 rounded shadow">
      <Card.Body className="text-center">
        {paymentState.status === 'succeeded' || paymentState.status === 'failed'
          ? <Button className="mb-3" variant="outline-primary" onClick={resendReceipt}>Resend Receipt</Button>
          : <></>
        }
        <Card.Title>{campaign.startsWith('mtd') ? 'Day' : 'Night'} {paymentState.index}</Card.Title>
        <Card.Subtitle className="mb-2 text-muted">{time.formatDate(paymentState.date)}</Card.Subtitle>
        <Card.Subtitle className="mb-2 text-muted">{displayCurrency({ amount: paymentState.total, currency: curr })}</Card.Subtitle>
        <Card.Text className="text-muted">
          {causes}
          {mtn}
          {fees}
        </Card.Text>
        {!hideStatus && <Card.Text className={{ 'text-danger': paymentState.status === 'cancelled' }}>Payment {paymentState.status}</Card.Text>}
        {/* Comparing the campaign and the current year is just a patch fix untill we generate the report and config */}
        {paymentState.status === 'succeeded' && !refunding && (actionable || isCampaignInCurrentYear(schedule.campaign)) && <Button variant="danger" onClick={refund}>Refund</Button>}
        {paymentState.status === 'failed' && !retryLoading && (actionable || isCampaignInCurrentYear(schedule.campaign)) && <Button variant="danger" onClick={retryPayment}>Retry</Button>}
        {isRetryError && <p className="mt-1 text-s text-center text-mtn-red min-h-xs">Error retrying</p>}
      </Card.Body>
    </Card>
  </Col>
}

export const sortPayments = (payments) => {
  return Object.entries(payments).map(p => {
    const [index, payment] = p
    payment.index = Number(index)
    return payment
  }).sort((a, b) => a.index > b.index)
}

export const PaymentBreakdown = ({ onRefund, schedule, onResendReceipt }) => {
  // Sort by ascending index number
  const payments = sortPayments(schedule.payments)

  return <>
    <h3 className='font-bold my-2'>Donations</h3>
    <hr />
    <Row>
      {payments.map(p => <Payment
        campaign={schedule.campaign}
        curr={schedule.currency}
        key={p.index}
        payFees={schedule.pay_fees}
        onRefund={onRefund}
        onResendReceipt={onResendReceipt}
        actionable={schedule.actionable}
        payment={p}
        schedule={schedule}
      />)}
    </Row>
  </>
}
