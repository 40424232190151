import { Col, Row } from 'react-bootstrap'
import time from '../service/time'
import { UrlRoutes, displayCurrency, getCountryByCode } from '@lib/services'
import { type FC } from 'react'
import { type APISchedule } from '@shamaazi/mytennights'

interface ScheduleSummaryProps {
  schedule: APISchedule
  donorId: string
}

export const ScheduleSummary: FC<ScheduleSummaryProps> = ({ schedule }) => {
  const money = (value: number): string => displayCurrency({ amount: value, currency: schedule.currency })
  const causes = schedule.causes.map(c => <Row key={c.name} className="mx-0">{c.name} {money(c.amount)}</Row>)
  const address = <>
    <Row className="mx-0">{schedule.donor_details.address}</Row>
    <Row className="mx-0">{getCountryByCode(schedule.donor_details.country)?.name}</Row>
    <Row className="mx-0">{schedule.donor_details.postcode}</Row>
  </>

  return <>
    <h1 className="mb-1">Schedule</h1>
    {schedule.cancelled ? <h6 className="text-danger">Cancelled</h6> : <></>}
    <h6 className="text-muted">Created at {time.formatTime(schedule.created_time)} on {time.formatDate(schedule.created_time)}</h6>
    <h6 className="text-muted">{schedule.timezone}</h6>
    <h6 className="text-muted">Campaign: {schedule.campaign}</h6>
    <hr />
    <Row className="mx-0 mb-2"><Col className="px-0">Name:</Col><Col className="px-0">{schedule.donor_details.first_name + ' ' + schedule.donor_details.last_name}</Col>

    </Row>
    <Row className="mx-0 mb-2"><Col className="px-0">Email:</Col><Col className="px-0">{schedule.donor_details.email}
    </Col></Row>
    <Row className="mx-0 mb-2"><Col className="px-0">Address:</Col><Col className="px-0">{address}</Col></Row>
    <hr />
    <Row className="mx-0 mb-2"><Col className="px-0">Charity:</Col><Col className="px-0">{schedule.charity_name}</Col></Row>
    <Row className="mx-0 mb-2">
      <Col className="px-0">Causes:</Col>
      <Col>{causes}</Col>
    </Row>
    <Row className="mx-0 mb-2"><Col className="px-0">Tip:</Col><Col className="px-0">{money(schedule.tip)}</Col></Row>
    {schedule.pay_fees ? <Row className="mx-0 mb-2"><Col className="px-0">Transaction Fee:</Col><Col className="px-0">{money(schedule.fee)}</Col></Row> : <></>}
    <hr />
    <Row className="mx-0 mb-5"><Col className="px-0">Total:</Col><Col className="px-0">{displayCurrency({
      amount: schedule.total,
      currency: schedule.currency,
      suffix: true
    })}</Col></Row>
    { schedule.is_matched && <hr />}
    { schedule.is_matched && <Row className="mx-0 mb-2"><Col className="px-0">Matched Amount:</Col><Col className="px-0">{money(schedule.matched_amount)}</Col></Row>}
    {schedule.onward_chain_codes_email && <hr />}
    {schedule.onward_chain_codes_email && <Row className="mx-0 mb-2"><Col className="px-0">Friends Donated</Col><Col className="px-0">
      {schedule.onward_chain_codes_email?.map((email, index) => (
        <div key={index}>{email}</div>
      ))}
    </Col></Row>}
    <Row className="mx-0 mb-2"><Col className="px-0">ChainCode:</Col> {schedule.chain_code ? <Col className="px-0 font-bold">{schedule.chain_code}</Col> : <Col className="px-0 font-bold">N/A</Col>}</Row>
    <Row className="mx-0 mb-4"><Col className="px-0">
            <a
                target="_blank"
                className='p-2 bg-gm-yellow font-bold rounded-md'
                href={UrlRoutes.buildCSdashboradShareUrl(schedule.givematch_share_code, schedule.campaign)} rel="noreferrer">
                Share link
            </a>
        </Col></Row>
    <hr/>
  </>
}

export default ScheduleSummary
